import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as autoplayVideo from './scripts/autoplay-video';
autoplayVideo.init();

import * as portalAnimation from './scripts/portal-animation';
portalAnimation.init();

import * as parallax from './scripts/parallax';
parallax.init();

import * as translateFollowMouse from './scripts/translate-follow-mouse';
translateFollowMouse.init();

import * as dynamicTabbing from './scripts/dynamic-tabbing';
dynamicTabbing.init();

import * as tabbingAnimations from './scripts/tabbing-animations';
tabbingAnimations.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as teaserAnimation from './scripts/teaser-animation';
teaserAnimation.init();


